import $ from 'jquery';

$(() => {
  // modalを開いた時にfocusを当てる
  $('.js-focusable-modal').on('shown.bs.modal', (event) => {
    event.target.querySelector('.js-focus-target').focus();
  });

  // modalを閉じる時に入力値を消す
  $('.js-cleanable-modal').on('hide.bs.modal', (event) => {
    event.target.querySelectorAll('.js-clean-target').forEach((field) => {
      field.value = '';
    });
  });

  // tooltip
  $('[data-toggle="tooltip"]').tooltip();

  // popover
  $('[data-toggle="popover"]').popover();
});
